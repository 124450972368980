import { Card, Steps, Form } from "antd";
import React, { useState } from "react";
import BookingPreview from "./components/BookingPreview";
import BookingSuccessfullyPage from "./components/BookingSuccessfullyPage";
import ChooseTimeSlotForm from "./components/ChooseTimeSlotForm";
import EntityServiceForm from "./components/EntityServiceForm";
import ContentWrapper from "@components/ContentWrapper";

const BookNowForm = () => {
  const [currentForm, setCurrentForm] = useState(0);
  const [bookingForm] = Form.useForm();
  const [bookingData, setBookingData] = useState({ purpose: "Travel" });
  const handleBookingDataUpdate = (args) =>
    setBookingData((prev, _) => {
      const _data = {
        ...prev,
        ...args,
      };
      return _data;
    });

  const onGoNext = () => {
    if (currentForm < 3) {
      setCurrentForm((index) => index + 1);
    }
  };
  const onGoBack = () => {
    if (currentForm > 0) {
      setCurrentForm((index) => index - 1);
    }
  };

  return (
    <ContentWrapper title="Booking a service">
      <div className="mt-5" />
      <div className="container">
        <Card className="mt-5">
          <Steps current={currentForm}>
            <Steps.Step title="Time slot"></Steps.Step>
            <Steps.Step title="Entity"></Steps.Step>
            <Steps.Step title="Preview"></Steps.Step>
            <Steps.Step title="Receipt"></Steps.Step>
          </Steps>
        </Card>

        <Card className="mt-3">
          {currentForm === 0 ? (
            <ChooseTimeSlotForm
              onNext={onGoNext}
              formData={bookingData}
              setScheduleDateAndTime={handleBookingDataUpdate}
            />
          ) : currentForm === 1 ? (
            <EntityServiceForm
              onBack={onGoBack}
              form={bookingForm}
              onNext={onGoNext}
              setFormData={handleBookingDataUpdate}
              formData={bookingData}
            />
          ) : currentForm === 2 ? (
            <BookingPreview
              formData={bookingData}
              onNext={onGoNext}
              onBack={onGoBack}
            />
          ) : (
            <BookingSuccessfullyPage />
          )}
        </Card>
      </div>
    </ContentWrapper>
  );
};

export default BookNowForm;
