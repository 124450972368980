import { createAsyncThunk } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { APIClient } from "../../services/api";
import {
  bookingHistoryUrl,
  doctorsReferralHistoryUrl,
  getClientBookedServicesUrl,
  getOrganizationsAttendedBookingsUrl,
  getOrganizationsUnAttendedBookingsUrl,
  markBookingAsAttendedUrl,
  markBookingAsPaidUrl,
} from "../../utils/network/endpoints";
import { addBookings, markBookingAsPaid } from "../slices/booking.slice";

const api = new APIClient();

export const fetchBookingsHistoryThunk = createAsyncThunk(
  "fetch_booking_history",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .get(bookingHistoryUrl)
      .then(({ data }) => {
        dispatch(addBookings(data.data));
        return fulfillWithValue(data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchClientBookingsThunk = createAsyncThunk(
  "fetch_client_booked_services",
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .get(getClientBookedServicesUrl)
      .then(({ data }) => {
        dispatch(addBookings(data.data));
        return fulfillWithValue(data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          notification.error({
            message: "Fetching bookings failed",
            description: err.message,
          });
        }
        return rejectWithValue(err);
      });
  }
);

export const fetchAttendedBookingsThunk = createAsyncThunk(
  "fetch_organizations_attended_bookings",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .get(getOrganizationsAttendedBookingsUrl)
      .then(({ data }) => {
        dispatch(addBookings(data.data));
        return fulfillWithValue(data);
      })
      .catch((error) => {
        message.error("Attended Bookings Fetch was unsuccessful");
        return rejectWithValue(error);
      });
  }
);

export const fetchUnAttendedBookingsThunk = createAsyncThunk(
  "fetch_organizations_unattended_bookings",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .get(getOrganizationsUnAttendedBookingsUrl)
      .then(({ data }) => {
        dispatch(addBookings(data.data));
        return fulfillWithValue(data);
      })
      .catch((error) => {
        message.error("Unattended Bookings Fetch was unsuccessful");
        return rejectWithValue(error);
      });
  }
);

export const markBookingAsAttendedThunk = createAsyncThunk(
  "mark_organizations_unattended_bookings_as_attended",
  async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .post(markBookingAsAttendedUrl.replace("{ID}", payload), {})
      .then(({ data }) => {
        dispatch(addBookings(data.data));
        message.success("Booking marked as attended");
        return fulfillWithValue(data);
      })
      .catch((error) => {
        message.error("This action failed, please try again");
        return rejectWithValue(error);
      });
  }
);

export const markBookingAsPaidThunk = createAsyncThunk(
  "mark_booking_as_paid",
  async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .post(markBookingAsPaidUrl, {
        booking_id: payload,
      })
      .then(({ data }) => {
        dispatch(markBookingAsPaid(data.data));
        message.success("Booking marked as paid");
        return fulfillWithValue(data);
      })
      .catch((error) => {
        message.error("This action failed, please try again");
        return rejectWithValue(error);
      });
  }
);


export const fetchDoctorReferralHistoryThunk = createAsyncThunk(
  'fetch/fetchDoctorReferralHistory', 
  async (_, {dispatch}) => {
    api.addAccessToken()
    return api.get(doctorsReferralHistoryUrl)
    .then(({data}) => {
      dispatch(addBookings(data.data))
    })
    .catch((error) => {

    })
  }
)