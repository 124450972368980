import { Button, Modal, Result, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const QrCodeScanner = ({ open, onClose, searchBookingCode }) => {
  const [scanned, setScanned] = useState(null);
  useEffect(() => {
    setScanned(null);
  }, []);
  const onUpdate = (err, result) => {
    if (!err) {
      setScanned(result.text);
      searchBookingCode(result.text);
    }
  };
  const handleRescan = () => {
    setScanned(null);
  };
  const onCloseModal = () => {
    setScanned("");
    onClose();
  };
  return (
    <Modal visible={open} onCancel={onCloseModal} footer={null}>
      {scanned ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "400px",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "1em",
          }}
        >
          <Result status={"success"}></Result>
          <Typography>{scanned}</Typography>
          <Button style={{ width: "100%" }} onClick={handleRescan}>
            Re-scan
          </Button>
        </div>
      ) : (
        <div>
          {open && <BarcodeScannerComponent height={300} onUpdate={onUpdate} />}
        </div>
      )}
    </Modal>
  );
};

export default QrCodeScanner;
