import React from "react";
import * as Sentry from "@sentry/react";
import Error500 from "../Error500";

const SentryErrorBoundary = ({ children }) => {
  return (
    // FIXME: remove onError attr
    <Sentry.ErrorBoundary onError={(error) => {console.log(error)}} fallback={<Error500 />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
