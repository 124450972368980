import { createSlice } from "@reduxjs/toolkit";
import { HTTPStatusType } from "../../utils/http_status_type";
import getExtraReducers from "../extraReducersCreator";
import * as thunks from "../thunks/booking.thunk";

const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  loading: false,
  history: [],
  filtered: [],
  list: [],
};

const extraReducers = getExtraReducers(thunks);
const bookingSlice = createSlice({
  name: "booking",
  initialState: initialState,
  reducers: {
    addBookings: (state, { payload }) => {
      state.history = payload;
      state.filtered = payload;
      state.list = payload;
    },
    searchBookingHistoryByBookingCode: (state, { payload }) => {
      state.filtered = state.history.filter((_bookedItem) =>
        _bookedItem?.code.includes(payload?.toUpperCase())
      );
    },
    searchBookingByBookedCode: (state, { payload }) => {
      state.filtered = state.list.filter((_bookedItem) =>
        _bookedItem?.code.includes(payload?.toUpperCase())
      );
    },
    resetFilteringBookingHistory: (state, actions) => {
      state.filtered = [...state.history];
    },
    filteringBookingHistory: (state, { payload }) => {
      let result = [...state.history];
      // filtering by service name
      result = state.history.filter((_bookedItem) => {
        const allTrue = [];
        // filter by service name
        payload?.serviceName &&
          allTrue.push(
            _bookedItem?.booked_entity_service?.service_name
              ?.toLowerCase()
              .includes(payload?.serviceName.toLowerCase())
          );
        // Filter by service type
        payload?.serviceType &&
          payload.serviceType.length > 0 &&
          allTrue.push(
            payload.serviceType.some(
              (_type) =>
                _type.toLowerCase() ===
                _bookedItem.booked_entity_service.service.toLowerCase()
            )
          );
        // Filter by organization name
        payload?.organizationName &&
          allTrue.push(
            _bookedItem.booked_entity_service.external_entity
              .toLowerCase()
              .includes(payload.organizationName.toLowerCase())
          );
        // Filter by price Range
        payload?.priceRange &&
          payload?.priceRange.length > 0 &&
          _bookedItem?.booked_entity_service?.price &&
          allTrue.push(
            payload.priceRange.some((price) => {
              const start = price.split("-")[0];
              const end = price.split("-")[1];
              const amount = parseFloat(
                _bookedItem?.booked_entity_service?.price.amount
              );
              if (end === "above") {
                return amount >= parseFloat(start);
              }
              return amount > parseFloat(start) && amount < parseInt(end);
            })
          );
        // FIlter by payment status 0:NOT PAID 1:PAID
        payload?.paymentStatus &&
          allTrue.push(
            _bookedItem.payed === Boolean(parseInt(payload.paymentStatus))
          );

        return allTrue.every((_flag) => _flag === true);
      });

      // updated filtered state
      state.filtered = result;
    },
    filteringClientBookings: (state, { payload }) => {
      state.filtered = state.data.filter(
        (item) =>
          (payload.service_name && item.booked_entity_service.service_name
            ? item.booked_entity_service?.service_name
                ?.toLowerCase()
                .includes(payload.service_name.toLowerCase())
            : true) &&
          (payload.client_name
            ? item.booked_by?.firstName
                .toLowerCase()
                .includes(
                  payload.client_name.toLowerCase() ||
                    item.booked_by?.lastName
                      .toLowerCase()
                      .includes(payload.client_name())
                )
            : true) &&
          (payload.client_email
            ? item.booked_by?.email
                .toLowerCase()
                .includes(payload.client_email?.toLowerCase())
            : true) &&
          (payload.service_status
            ? parseInt(payload.service_status) === 1
              ? item.payed
              : !item.payed
            : true) &&
          (payload.service_types.length > 0
            ? payload.service_types?.some(
                (type) =>
                  type.toLowerCase() ===
                  item.booked_entity_service?.service.toLowerCase()
              )
            : true) &&
          (payload.price_ranges.length > 0
            ? payload.price_ranges.some((price) => {
                const start = price.split("-")[0];
                const end = price.split("-")[1];
                const amount = parseFloat(
                  item?.booked_entity_service?.price.amount
                );
                if (end === "above") {
                  return amount >= parseFloat(start);
                }
                return amount > parseFloat(start) && amount < parseInt(end);
              })
            : true)
      );
    },
    markBookingAsPaid: (state, { payload }) => {
      const data = [...state.history];
      const paymentIndex = data.findIndex(
        (item) => parseInt(item.id) === parseInt(payload)
      );
      data[paymentIndex].payed = true;
      //
    },
  },
  extraReducers: extraReducers,
});

export const {
  addBookings,
  filteringBookingHistory,
  filteringClientBookings,
  resetFilteringBookingHistory,
  searchBookingHistoryByBookingCode,
  searchBookingByBookedCode,
  markBookingAsPaid
} = bookingSlice.actions;

export const getBookingState = (state) => state.booking

export default bookingSlice.reducer;
