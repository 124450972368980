import React, { useCallback, useEffect } from "react";
import {
  Statistic,
  Table,
  Card,
  Button,
  Input,
  Select,
  Form,
  Tooltip,
  Modal,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import { getTime } from "@utils/date_time_format";
import { ArrowRight } from "react-bootstrap-icons";
import ViewModal from "@components/modals/ViewModal";
import { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { priceRange } from "@utils/bookingsFilter.utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faCoins } from "@fortawesome/free-solid-svg-icons";
import BookingCertificateModal from "@components/modals/BookingCertificateModal";
import ContentWrapper from "@components/ContentWrapper";
import { fetchFeaturedServicesThunk } from "@store/thunks/service.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import { fetchBookingsHistoryThunk } from "@store/thunks/booking.thunk";
import {
  filteringBookingHistory,
  resetFilteringBookingHistory,
  searchBookingHistoryByBookingCode,
} from "@store/slices/booking.slice";
import { UserRole } from "@utils/roles";
import { fetchDoctorReferralHistoryThunk } from "@store/thunks/booking.thunk";
import { getUserProfile } from "@utils/persistent_data/local_storage";
// import ReferralBookingDetail from "../../Referral/ReferralBookingDetail";
import { APIClient } from "@services/api";
import { generateInvoiceUrl } from "@utils/network/endpoints";
import CustomLoader from "@components/CustomLoader";
import PayStackAPI from "@services/paystack";
import pageRoutes from "@utils/page-routes";
import { v4 } from "uuid";
import FilterModal from "@components/modals/FilterModal";

const api = new APIClient();

const ModalOptions = {
  None: null,
  ViewDetail: "view-detail",
  ViewCertificate: "view-certificate",
  ViewPaymentForm: "view-payment-form",
};

export default function BookingHistory() {
  const [modal, setModal] = useState(ModalOptions.None);
  const [amount, setAmount] = useState(0);
  const [activeRecord, setActiveRecord] = useState(null);
  const [paymentFormInstance] = Form.useForm();
  const group = getUserProfile()?.group.toLowerCase();
  const dispatch = useDispatch();

  const bookingState = useCustomSelector((state) => state.booking);
  const serviceState = useCustomSelector((state) => state.service);

  const [filterParameter, setFilterPrams] = useState({
    priceRange: [],
    serviceType: [],
    organizationName: null,
    serviceName: null,
    paymentStatus: null,
  });

  const showModal = (modal, data = null) => {
    setModal(modal);
    setActiveRecord(modal !== null ? data : null);
  };

  const filterHandler = useCallback(
    (extraParams) => {
      const params = {
        ...filterParameter,
        ...extraParams,
      };
      setFilterPrams(params);
      dispatch(filteringBookingHistory(params));
    },
    [dispatch, filterParameter]
  );

  const handleInputsChange = (e) =>
    filterHandler({ [e.target.name]: e.target.value });

  const handleSelectorChange = (key, value) => filterHandler({ [key]: value });

  const onSearch = useCallback(
    (e) => {
      dispatch(searchBookingHistoryByBookingCode(e.target.value));
    },
    [dispatch]
  );

  const filterResetHandler = useCallback(() => {
    setFilterPrams({
      ...filterParameter,
      priceRange: [],
      serviceType: [],
      organizationName: null,
      serviceName: null,
      paymentStatus: null,
    });

    dispatch(resetFilteringBookingHistory());
  }, [dispatch, filterParameter]);

  const fetchHistoryBooking = useCallback(() => {
    if (group === UserRole.DOCTOR) dispatch(fetchDoctorReferralHistoryThunk());
    if (group === UserRole.PATIENT) dispatch(fetchBookingsHistoryThunk());
  }, [dispatch, group]);

  const generateInvoice = useCallback(
    async (data, onSuccess = () => {}) => {
      const code = data?.code;

      // notification
      message.loading(
        "Invoice is been generated for booking #".concat(code),
        2
      );
      api.addAccessToken();

      try {
        const response = await api.get(generateInvoiceUrl(code));
        const user = response.data?.data?.generated_for;
        console.log(response.data.data);

        setAmount(response.data?.data.payable_amount);

        user?.name && paymentFormInstance.setFieldValue("name", user?.name);
        user?.email && paymentFormInstance.setFieldValue("email", user?.email);
        user?.phone_number &&
          paymentFormInstance.setFieldValue("phone", user?.phone_number);

        // trigger form
        await paymentFormInstance.validateFields(["name", "email", "phone"]);
        message.success("Invoice Generated Successfully");
        // onFulfilled
        onSuccess();
      } catch (error) {
        message.error(
          "Invoice Generating Failed Unexpectedly, please try again"
        );
      }
    },
    [paymentFormInstance]
  );

  const makePaymentOnFinishHandler = async (values) => {
    const price = amount.split(" ");
    const ref = v4();
    try {
      const response = await PayStackAPI.initializeTransaction({
        amount: price.length >= 1 ? price[1] : 0,
        email: values?.email,
        // currency: price.length > 0 ? price[0] : "GHS",
        currency: "GHS",
        mobile_money: {
          phone: values?.phone || "",
          provider: "MTN",
        },
        callback_url:
          window.location.origin + pageRoutes.payment_confirmation(ref),
        reference: ref,
      });

      const url = response.data.data.authorization_url;
      setTimeout(() => {
        // 👇️ redirects to an external URL
        window.location.replace(url);
      }, 3000);
    } catch (error) {
      // console.log("error", error);
    }
  };

  const openPaymentModal = (book) =>
    generateInvoice(book, () => showModal(ModalOptions.ViewPaymentForm, book));

  useEffect(() => {
    fetchHistoryBooking();
    // return () => {
    //   filterResetHandler();
    // };
  }, [dispatch, fetchHistoryBooking]);

  useEffect(() => {
    dispatch(fetchFeaturedServicesThunk());
  }, [dispatch]);

  const columns = [
    {
      key: "service_name",
      title: "Name",
      dataIndex: "booked_entity_service",
      render: (value) => <p> {value?.name} </p>,
    },
    {
      key: "booking_code",
      title: "Booking Code",
      dataIndex: "code",
    },
    {
      key: "serviceType",
      title: "Service type",
      dataIndex: "booked_entity_service",
      render: (value) => <p> {value?.service} </p>,
    },
    {
      key: "organization",
      title: "Organization",
      dataIndex: "booked_entity_service",
      render: (value) => <p> {value?.external_entity} </p>,
    },
    {
      key: "price",
      title: "Price",
      dataIndex: "booked_entity_service",
      render: (value) => (
        <Statistic
          prefix={value?.price?.currency}
          valueStyle={{ fontSize: "1rem" }}
          value={value?.price?.amount}
        />
      ),
    },
    {
      key: "time_Slots",
      title: "Time",
      dataIndex: "entity_booking_schedule",
      render: (value, record) => (
        <div className="d-flex flex-column">
          <p> {moment(record.schedule_date).format("dddd LL")} </p>
          <div className="d-flex">
            {getTime(value.time_slot?.split("-")[0].trim())}
            <ArrowRight style={{ fontSize: "1.5rem" }} className="mx-2" />

            {getTime(value.time_slot?.split("-")[1].trim())}
          </div>
        </div>
      ),
    },

    {
      key: "detail",
      title: "Detail",
      render: (_, record) => (
        <div className="flex items-center gap-4">
          <Tooltip title="View Detail">
            <EyeOutlined
              onClick={() => showModal(ModalOptions.ViewDetail, record)}
              style={{ fontSize: "1.2rem" }}
            />
          </Tooltip>

          <Tooltip title="View Certificate">
            <Button
              onClick={() => showModal(ModalOptions.ViewCertificate, record)}
              type="link"
              style={{ padding: "0px", border: "none" }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "1rem" }}
                icon={faCertificate}
              />
            </Button>
          </Tooltip>
        </div>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "Action",
      render: (_, book) => {
        return book.booked_entity_service?.payment_required ? (
          <Button onClick={() => openPaymentModal(book)}>Make payment</Button>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <>
      {group === UserRole.PATIENT && (
        <ViewModal
          booking={activeRecord}
          onClose={() => showModal(null)}
          open={modal === ModalOptions.ViewDetail}
        />
      )}

      <BookingCertificateModal
        onClose={() => showModal(null)}
        open={modal === ModalOptions.ViewCertificate}
        booking={activeRecord}
      />
      {!(group === UserRole.DOCTOR && Boolean(activeRecord)) && (
        <ContentWrapper
          title={
            group !== UserRole.DOCTOR ? "Booked Services" : "Referral History"
          }
        >
          <Card className="mt-5">
            {/* <Collapse accordion>
              <Collapse.Panel header="Search bar">
                <Row
                  align="center"
                  justify="center"
                  style={{ columnGap: "1em", rowGap: "1em" }}
                >
                  <Col xs={24} md={24}>
                    <Input.Search
                      placeholder="Search by booking code"
                      onChange={onSearch}
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel header="Filter bar">
                <Form>
                  <Row style={{ columnGap: "1em" }}>
                    <Col xs={24} md={11}>
                      <Form.Item>
                        <Input
                          value={filterParameter.serviceName}
                          name="serviceName"
                          onChange={handleInputsChange}
                          placeholder="Search by Service name"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                      <Form.Item>
                        <Select
                          onChange={(value) =>
                            handleSelectorChange("serviceType", value)
                          }
                          value={filterParameter.serviceType}
                          mode="multiple"
                          loading={serviceState.loading}
                          placeholder="Choose service types"
                        >
                          {serviceState.state.featuredServices?.map(
                            (_feature, index) => (
                              <Select.Option
                                key={index}
                                value={_feature?.service?.details?.name}
                              >
                                {_feature?.service?.details?.name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                      <Form.Item>
                        <Input
                          value={filterParameter.organizationName}
                          name="organizationName"
                          onChange={handleInputsChange}
                          placeholder="Search by Organization's name"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={11}>
                      <Form.Item>
                        <Select
                          value={filterParameter.priceRange}
                          onChange={(value) =>
                            handleSelectorChange("priceRange", value)
                          }
                          placeholder="Filter by price ranges"
                          mode="multiple"
                        >
                          {priceRange?.map((item, index) => (
                            <Select.Option key={index} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                      <Form.Item>
                        <Select
                          value={filterParameter.paymentStatus}
                          allowClear
                          onChange={(value) =>
                            handleSelectorChange("paymentStatus", value)
                          }
                          placeholder="Filter by payment status"
                        >
                          <Select.Option key="0" value="0">
                            Unpaid
                          </Select.Option>
                          <Select.Option key="1" value="1">
                            Has paid
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                      <Button
                        style={{ width: "100%" }}
                        onClick={filterResetHandler}
                        type="primary"
                      >
                        Clear Filter
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Collapse.Panel>
            </Collapse> */}

            <Table
              rowKey={(record) => record?.id}
              style={{ overflowX: "auto" }}
              pagination={10}
              dataSource={bookingState.state?.filtered}
              loading={bookingState.loading}
              columns={columns}
            />
          </Card>
        </ContentWrapper>
      )}
      <Modal
        open={modal === ModalOptions.ViewPaymentForm}
        title="Enter Payment Details"
        onOk={paymentFormInstance.submit}
        onCancel={() => showModal(null)}
      >
        <CustomLoader loading={false}>
          <div className="bg-gray-200 rounded-lg flex justify-between p-5">
            <div className="flex items-center gap-4">
              <div className="square-50 border bg-gray-100 center rounded-lg text-yellow-500">
                <FontAwesomeIcon icon={faCoins} size="lg" />
              </div>
              <div>
                <h4 className="m-0">
                  {activeRecord?.booked_entity_service?.service_name || ""}
                </h4>
                <small>
                  <strong className="text-yellow-600">
                    {activeRecord?.booked_entity_service?.external_entity || ""}
                  </strong>
                </small>
              </div>
            </div>
            <div>
              <h3 className="font-thin">
                {activeRecord?.booked_entity_service?.price?.amount || ""}
                <sub>
                  /{activeRecord?.booked_entity_service?.price?.currency || ""}
                </sub>
              </h3>
            </div>
          </div>
          <br />
          <Form
            form={paymentFormInstance}
            layout="vertical"
            onFinish={makePaymentOnFinishHandler}
          >
            <Form.Item
              rules={[{ required: true, message: "Please enter your name!" }]}
              name={"name"}
              label="Name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              required
              name={"email"}
              label="Email"
              rules={[{ required: true, message: "Please enter your email!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              required
              name={"phone"}
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter your phone number!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </CustomLoader>
      </Modal>

      <FilterModal>
        <Form>
          <div className="grid grid-cols-2 gap-2">
            <Form.Item className="">
              <Input
                value={filterParameter.serviceName}
                name="serviceName"
                onChange={handleInputsChange}
                placeholder="Search by Service name"
              />
            </Form.Item>
            <Form.Item className="">
              <Input
                value={filterParameter.serviceName}
                name="serviceName"
                onChange={handleInputsChange}
                placeholder="Search by Service name"
              />
            </Form.Item>
            <Form.Item>
              <Select
                onChange={(value) => handleSelectorChange("serviceType", value)}
                value={filterParameter.serviceType}
                mode="multiple"
                loading={serviceState.loading}
                placeholder="Choose service types"
              >
                {serviceState.state.featuredServices?.map((_feature, index) => (
                  <Select.Option
                    key={index}
                    value={_feature?.service?.details?.name}
                  >
                    {_feature?.service?.details?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Input
                value={filterParameter.organizationName}
                name="organizationName"
                onChange={handleInputsChange}
                placeholder="Search by Organization's name"
              />
            </Form.Item>

            <Form.Item>
              <Select
                value={filterParameter.priceRange}
                onChange={(value) => handleSelectorChange("priceRange", value)}
                placeholder="Filter by price ranges"
                mode="multiple"
              >
                {priceRange?.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Select
                value={filterParameter.paymentStatus}
                allowClear
                onChange={(value) =>
                  handleSelectorChange("paymentStatus", value)
                }
                placeholder="Filter by payment status"
              >
                <Select.Option key="0" value="0">
                  Unpaid
                </Select.Option>
                <Select.Option key="1" value="1">
                  Has paid
                </Select.Option>
              </Select>
            </Form.Item>

            <Button className="btn btn-primary" onClick={filterResetHandler}>
              Clear Filter
            </Button>
          </div>
        </Form>
      </FilterModal>

      {/* {group === UserRole.DOCTOR && Boolean(currentSelectedBooking) && (
        <ReferralBookingDetail
          booking={currentSelectedBooking}
          goBack={() => {
            setCurrentSelectedBooking(null);
          }}
        />
      )} */}
    </>
  );
}
