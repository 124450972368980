import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import BookingCertificate from "@components/certificates";

const BookingSuccessfullyPage = () => {
  const result = useSelector(
    (state) => state.bookNowService?.bookingResult || undefined
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "certificate",
    pageStyle: () => `
    
    `,
  });

  return Object.keys(result)?.length > 0 ? (
    <div
      style={{ rowGap: "2em" }}
      className="flex flex-col items-center justify-center"
    >
      <BookingCertificate result={result} printRef={componentRef} />
      <div className="flex ml-auto mt-4">
        <Button
          icon={
            <FontAwesomeIcon
              className="px-1"
              style={{ fontSize: "1.5rem" }}
              icon={faPrint}
            />
          }
          className="ml-auto"
          shape="round"
          style={{ height: "40px" }}
          type="primary"
          onClick={handlePrint}
        >
          Print Certificate
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BookingSuccessfullyPage;
