import React from "react";
import QrCode from "qrcode.react";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag, Typography } from "antd";
import logo from "../../assets/img/brand/alkemy.png";
import {
  getCalDate,
  getEndTimeFromFullString,
  getStartTimeFromFullString,
} from "../../utils/date_time_format";
const BookingCertificate = ({ printRef, result }) => {
  return (
    <div
      style={{
        borderRadius: "15px",
        maxWidth: "600px",
        border: "1px solid #CCCCCC",
        height: "auto",
      }}
      ref={printRef}
    >
      <div className="p-3">
        <div className="flex">
          <Typography.Title level={5} style={{ color: "#CCCCCC" }}>
            {result?.service?.service_name}
          </Typography.Title>
          {!result?.payed ? (
            <Tag
              style={{ borderRadius: "20px", padding: ".3em 1em" }}
              className="ml-auto"
              color="red"
            >
              Unpaid
            </Tag>
          ) : (
            <Tag
              style={{ borderRadius: "20px", padding: ".3em 1em" }}
              className="ml-auto"
              color="green"
            >
              Paid
            </Tag>
          )}
        </div>
        <Typography.Title level={4}>{result?.service?.test}</Typography.Title>
        <Typography.Title level={3}>{result?.service?.price}</Typography.Title>

        <div style={{ columnGap: "1em" }} className="flex mt-5">
          <div style={{ rowGap: "1em" }} className="flex flex-col">
            <div style={{ columnGap: "1em" }} className="flex">
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ fontSize: "1.2rem", color: "#CCCCCC" }}
              />
              <Typography>{getCalDate(result?.date)}</Typography>
            </div>
            <ul className="flex flex-col gap-4">
              <li className="flex items-center gap-2">
                <Tag color="blue" className="rounded-full font-bold">
                  From
                </Tag>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ fontSize: "1.2rem", color: "#CCCCCC" }}
                />{" "}
                <span>
                  {" "}
                  {getStartTimeFromFullString(
                    result?.entity_booking_schedule?.time_slot
                  )}
                </span>
              </li>
              <li className="flex items-center gap-2">
                <Tag color="blue" className="rounded-full font-bold">
                  To
                </Tag>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ fontSize: "1.2rem", color: "#CCCCCC" }}
                />{" "}
                <span>
                  {" "}
                  {getEndTimeFromFullString(
                    result?.entity_booking_schedule?.time_slot
                  )}
                </span>
              </li>
            </ul>
          </div>
          <div className="ml-auto">
            <QrCode
              style={{ width: "150px", height: "150px" }}
              value={result?.code}
            />
            <p
              style={{ letterSpacing: ".2rem" }}
              className="font-bold text-center"
            >
              {result?.code}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "50px",
          backgroundColor: "#f9f9f9",
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img width="100px" src={logo} alt="alkemy" />
      </div>
    </div>
  );
};

export default BookingCertificate;
